.games {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.games h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.games p {
  font-size: 1.2em;
  line-height: 1.6;
}

.game-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.game-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.game-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.game-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}

.game-details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.game-details {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 60%; /* Reduce the max width for a thinner appearance */
  max-height: 90vh; /* Ensure content fits within the screen */
  text-align: center;
  overflow-y: auto; /* Scroll if content exceeds modal height */
  margin: 0 auto; /* Center the details box on the screen */
}


.game-details h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.game-details p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.game-details button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  background-color: #74483f;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.game-details button:hover {
  background-color: rgb(209, 26, 2);
}

/* Styles specific to The Warden game details */
.warden-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 100%;
}

.warden-image {
  width: 30%;
  border-radius: 5px;
}

.warden-text {
  flex: 1;
  font-size: 1.2em;
  line-height: 1.6;
}

/* Fix for bullet points being too far from text */
ul {
  list-style-position: inside;
}
