.footer {
    background-color: transparent; /* Make the background transparent */
    padding: 20px;
    color: white;
    text-align: left;
}
  
.footer a {
    color: #f9f9f9;
    text-decoration: none;
}
  
.footer a:hover {
    text-decoration: underline;
}

.contact-icon {
    display: inline-flex; /* Ensure the icon behaves like an inline element */
    align-items: center; /* Align icon vertically with the text */
    transition: transform 0.3s;
    vertical-align: middle; /* Align icon with the middle of the text */
    margin-left: 5px; /* Add space between text and icon */
}
  
.contact-icon:hover {
    transform: scale(1.2); /* Scale the icon on hover */
    color: rgb(209, 26, 2);
    transition: color 0.3s ease;
}
