.about-me-carousel {
  width: 100%; /* Full width of the container */
  max-width: 400px; /* Limit the carousel width to 400px */
  margin: 0 auto; /* Center the carousel horizontally */
  padding-top: 2rem; /* Add space above the carousel */
  position: relative; /* Position the carousel relatively within its container */
}

/* Carousel images */
.about-me-carousel img {
  max-height: 400px; /* Limit image height */
  width: 100%; /* Make the image responsive to the container's width */
  object-fit: cover; /* Keep aspect ratio while filling the container */
  opacity: 0; /* Initially invisible */
  transition: opacity 1s ease-in-out; /* Smooth fade transition for images */
  position: absolute; /* Position images on top of each other */
  top: 0;
  left: 0;
}

/* Carousel active image */
.carousel-item.active img {
  opacity: 1; /* Make the active image fully visible */
  z-index: 1; /* Bring the active image on top */
}

/* Default carousel image */
.carousel-item img {
  z-index: 0; /* Ensure non-active images are beneath active ones */
}

/* Carousel item (container for each image) */
.carousel-item {
  height: 400px; /* Set fixed height for carousel items */
  position: relative; /* Ensure items position properly in the container */
}