.skills {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.skills h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.skills p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.skill-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  width: 45%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skill-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.skill-item p {
  font-size: 1.1em;
  line-height: 1.5;
}

.experience {
  margin-top: 40px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.experience h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
}

.experience ul {
  list-style-type: disc;
  margin-left: 0; /* Remove margin to align with the left of the container */
  padding-left: 20px; /* Add padding for spacing between bullets and text */
}

.experience li {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 10px;
}
