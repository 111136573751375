.contact {
  padding: 20px;
  background-color: #f9f9f9; /* Adjust to fit your theme */
  border-radius: 5px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.contact h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.contact p {
  font-size: 1.2em;
  line-height: 1.6;
}

.contact-icons {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust spacing between icons */
  margin-top: 20px;
  position: relative; /* Needed for the tooltip positioning */
}

.contact-icons a {
  color: #74483f; /* Icon color */
  transition: transform 0.2s ease-in-out;
  position: relative;
}

.contact-icons a:hover {
  transform: scale(1.2); /* Enlarge on hover */
  color: rgb(209, 26, 2); /* Change color on hover */
}

/* Tooltip CSS only for the email icon */
.email-icon::after {
  content: 'coynebbc@gmail.com'; /* Your email text */
  position: absolute;
  bottom: 150%; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8); /* Background for the tooltip */
  color: #fff; /* Tooltip text color */
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  font-size: 0.9em;
}

.email-icon:hover::after {
  opacity: 1;
  visibility: visible;
}

