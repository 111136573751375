.about-me {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.about-me-content {
  display: flex;
  align-items: center; /* Aligns the items (image and text) vertically */
  margin-bottom: 20px;
}

.profile-image {
  width: 200px; /* Adjust the size as needed */
  height: auto;
  border-radius: 50%;
  margin-right: 20px; /* Space between the image and the text */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-image:hover {
  transform: scale(1.1); /* Zoom in the image by 10% */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-me-text {
  max-width: 550px; /* Optional: Limit the width of the text block */
}

.about-me h2 {
  font-size: 2em;
  margin-bottom: 10px;
  margin-left: 0;
}

.hobbies-section {
  margin-left: 0; /* Align to the left margin */
  text-align: left;
}

.hobbies-section h2, .hobbies-section h3, .hobbies-section p {
  margin-left: 0; /* Align to the left margin */
}

.about-me h3 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-left: 0; /* Align to the left margin */
}

.about-me p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-left: 0; /* Align to the left margin */
}
