.resume {
    padding: 20px;
    background-color: #f9f9f9; /* Adjust to fit your theme */
    border-radius: 5px;
    max-width: 800px;
    margin: 20px auto;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .resume h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .resume p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  