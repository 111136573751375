.header {
  width: 100%;
  padding: 10px 20px; /* Add some padding if needed */
  background-color: transparent;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content h1 {
  margin: 0; /* Remove any default margin */
  font-size: 24px; /* Adjust the font size as needed */
  color: #dcdedd;
}

.nav-bar {
  text-align: right;
  padding: 1px;
  line-height: 3em;
  
}

.nav-bar a {
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 8px;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition for hover effects */
}

.nav-bar a.active {
  border: 0px solid;
  border-bottom-width: 2px;
}

.nav-bar a:hover {
  color: #f9f9f9; /* Text greys out on hover */
  text-decoration: underline; /* Adds an underline on hover */
}

@media only screen and (max-width: 620px) {
  .nav-bar {
    line-height: 2em;
  }

  .nav-bar a {
    margin-left: 9px;
    margin-right: 9px;
    padding-bottom: 0px;
  }
}
