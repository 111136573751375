.download-resume {
  text-align: center;
  margin: 20px 0;
}

.download-resume button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  background-color: #74483f;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-resume button:hover {
  background-color: rgb(209, 26, 2);
}
