.App {
  text-align: center;
  min-height: 100vh; /* Ensure the gradient covers the full viewport */
}

body, html {
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, #74483f, #4b4643);
  height: 100%;
  overflow-x: hidden;
}

footer {
  background-color: #4b4643; /* Match footer with the end of the gradient */
  padding: 20px;
  color: white;
  text-align: center;
}
